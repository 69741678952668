<template>
    <b-container class="container">
        <div class="mb-4 mt-3">
            <h1>Talepler</h1>
        </div>
        <b-col>
            <b-row>
                <b-table responsive striped hover :fields="fields" :items="talepler" v-if="talepler">
                    <template v-slot:cell(bel_talep_durum)="durum">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="durum.item.bel_talep_durum"
                            unchecked-value="0"
                            value="1"
                            disabled
                            name="checkbox-1"></b-form-checkbox>
                    </template>
                    <template v-slot:cell(firma_sey)="data">
                        <div class="text-right" >
                            <b-button class="badge" @click="sil(data.item.bel_talep_id)" size="sm" variant="danger"><b-icon-trash></b-icon-trash></b-button>
                        </div>
                    </template>
                </b-table>
                <p v-else>Sistemde kayıtlı veri bulunamadı!</p>
            </b-row>
        </b-col>
        <b-button variant="primary" class="ekleButton" to="/bel_sayfa_form">Yeni Ekle</b-button>
    </b-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data(){
        return{
            fields : [
                {
                    "key" : "bel_talep_tarih",
                    "label" : "Tarih",
                    "thStyle": { width: '150px'},
                    "sortable": "true"
                },
                {
                    "key" : "bel_talep_adsoyad",
                    "label" : "Ad Soyad",
                    "thStyle": { width: '150px'},
                    "sortable": "true"
                },
                {
                    "key" : "bel_talep_tel",
                    "label" : "Telefon",
                    "thStyle": { width: '150px'}
                },
                {
                    "key" : "bel_talep_miktar",
                    "label" : "Miktar",
                    "thStyle": { width: '150px'}
                },
                {
                    "key" : "bel_talep_adres",
                    "label" : "Adres",
                    "thStyle": { width: '150px'}
                },
                {
                    "key" : "bel_talep_durum",
                    "label" : "Durum",
                    "thStyle": { width: '50px'}
                },
                {
                    "key" : "firma_sey",
                    "label" : "",
                    "thStyle": { width: '50px'}
                },
            ]
        }
    },

    mounted(){
        this.$store.dispatch("users");
    },

    computed: {
        users(){
            return this.$store.state.users;
        },
        talepler(){
            return this.$store.state.belTalepler;
        }
    },

    methods: {
        sil(a){
            Swal.fire({
                title: 'Emin Misin?',
                text: "Bu bilgi kalıcı olarak silinecektir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet',
                cancelButtonText: 'Hayır',
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("belTalepDel", {id: a})
                    .then(r => {
                        Swal.fire(
                            r.status ? 'Tebrikler!' : 'Hata oluştu!',
                            r.message,
                            r.status ? 'success' : 'warning'
                        )
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.ekleButton{
    position: fixed;
    right: 15px;
    bottom: 15px;
}
h1{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}
</style>